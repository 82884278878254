@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@200;500&family=Poppins:wght@100;300;400;500&display=swap");

* {
  font-family: "Poppins", sans-serif;
}

::-webkit-scrollbar {
  width: 7px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #1d2346ee;
  background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #02132e;
  border-radius: 5px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1c345c;
}
